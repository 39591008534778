
import { JwtHelperService } from "@auth0/angular-jwt";
const helper = new JwtHelperService();


export function hasToken() {
  return !!getToken();
  // return !!getTokenPermission();
}

export function getToken(): String {
  return window.localStorage['PHOENIXTOKEN'];
}

// export function getTokenPermission(): String {
//   return window.localStorage['TOKENPERMISSIONS'];
// }

// export function saveTokenPermission(tokenPermission: String) {
//   window.localStorage['TOKENPERMISSIONS'] = tokenPermission;
// }

export function saveToken(token: String) {
  window.localStorage['PHOENIXTOKEN'] = token;
}

export function destroyToken() {
  window.localStorage.removeItem('PHOENIXTOKEN');
}

export function decodedToken() {
  var myRawToken = getToken() ?? '';
  // console.log(String(myRawToken));
  
  return helper.decodeToken(String(myRawToken));
}

// export function decodedTokenPermission() {
//   const jwtHelper = new JwtHelperService();

//   // Get the token from wherever it's stored
//   const tokenPermission = getTokenPermission();

//   if (tokenPermission) {
//     const decodedToken = jwtHelper.decodeToken(String(tokenPermission));
//     return decodedToken.permissions || [];
//   } else {
//     return [];
//   }
// }

export function isTokenExpired(): boolean {
  const myRawToken = getToken().toString();
  console.log(myRawToken);
  
  return helper.isTokenExpired(myRawToken);
}


export function tokenExpirationDate(): Date {
  const myRawToken = getToken().toString();
  console.log(myRawToken);
  
  return helper.getTokenExpirationDate(myRawToken);
}

export function clearSessionData() {
  // window.localStorage.setItem('sesUsr', JSON.stringify({}));
  window.localStorage.setItem('TGLANG', '');
  window.localStorage.setItem('TGDATEFORMAT', '');
  window.localStorage.setItem('TGDATERAW', '');
  window.localStorage.setItem('TGDATESEP', '');
  window.localStorage.setItem('TGTIMEFORMAT', '');
  window.localStorage.setItem('TGTHEME', '');
  window.localStorage.setItem('TGFONT', '');
  window.localStorage.setItem('ISOTPVERIFIED', '');
}