import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject, Subject, of } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { saveToken, destroyToken, hasToken, decodedToken, clearSessionData } from '../helpers/token.helper';
import { User, IUserAPI } from '../models/';
import { map, distinctUntilChanged, take, catchError } from 'rxjs/operators';
import { ApiAdapter } from './user/api.adapter.service';
import { get } from "lodash";
import { PORTALTYPE } from "../helpers/enum.helper";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppLoaderService } from './app-loader.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);

  private _siteInfo = new BehaviorSubject(null);
  getSiteInfo = this._siteInfo.asObservable();

  portalType = '';
  errorMessages: any;



  // sos notification

  public arrayOfSosSub = new Subject();

  //nomovement notification
  public arrayOfNomovementSub = new ReplaySubject(1000);

  // patient details notification

  public arrayOfPatientSub = new ReplaySubject(1000);


  constructor(
    private apiService: ApiService,
    private router: Router,
    private adapter: ApiAdapter,
    private snackBar: MatSnackBar,  
    private loader: AppLoaderService,
  ) {

    this.isAuthenticatedSubject.next(hasToken());
    this.currentUserSubject.next(this.getSessionUser());

  }
  
  signin(formData): Observable<IUserAPI> {

    return this.apiService.post('login', formData).pipe(map(response => this.adapter.adapt(response)),
    catchError(error => {
      // let errorMessage = 'An unexpected error occurred. Please try again later.';

      if (error.status === 500) {
        this.errorMessages = 'Internal Server Error. Something went wrong.';
      } else if (error.status === 404) {
        this.errorMessages = 'Resource not found. Please check your request.';
      }

      this.snackBar.open(this.errorMessages, 'Close', {
        duration: 5000,
      });

      this.loader.close();
      return of(null);
    }));
  }
  

  siginPermission(formData): Observable<IUserAPI> {
    return this.apiService.post('loginNew', formData).pipe(
    catchError(error => {
      // let errorMessage = 'An unexpected error occurred. Please try again later.';

      if (error.status === 500) {
        this.errorMessages = 'Internal Server Error. Something went wrong.';
      } else if (error.status === 404) {
        this.errorMessages = 'Resource not found. Please check your request.';
      }

      this.snackBar.open(this.errorMessages, 'Close', {
        duration: 5000,
      });

      this.loader.close();
      return of(null);
    })
    );
  }

  otpVerify(formData): Observable<IUserAPI> {
    return this.apiService.post('login', formData).pipe(map(response => this.adapter.adapt(response)),
    catchError(error => {
      // let errorMessage = 'An unexpected error occurred. Please try again later.';

      if (error.status === 500) {
        this.errorMessages = 'Internal Server Error. Something went wrong.';
      } else if (error.status === 404) {
        this.errorMessages = 'Resource not found. Please check your request.';
      }

      this.snackBar.open(this.errorMessages, 'Close', {
        duration: 5000,
      });

      this.loader.close();
      return of(null);
    }));
  }

  forgetpassword(formData): Observable<IUserAPI> {
    return this.apiService.post('forget', formData).pipe(
		  catchError((error) => {
			// let errorMessage = 'An unexpected error occurred. Please try again later.';
			if (error.status === 500) {
			  this.errorMessages = 'Internal Server Error. Something went wrong.';
			} else if (error.status === 404) {
			  this.errorMessages = 'Resource not found. Please check your request.';
			} 
			this.snackBar.open(this.errorMessages, 'Close', {
			  duration: 5000,
			});
			this.loader.close();
			return of(null);
		  })
		);

  }


  setAuth(user: User) {
    // Save JWT sent from server in localstorage
    saveToken(user.token);  
    window.localStorage.setItem('sesUsr', JSON.stringify(user));
    // Set current user data into observable
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    destroyToken();
    clearSessionData();
    // Set current user to an empty object
    this.currentUserSubject.next({} as User);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);

  }


  sendResetLink(model: User): Observable<IUserAPI> {
    let formData = new FormData();
    formData.append('email', model.email);
    return this.apiService.post('hospital/forget', formData).pipe(map(response => this.adapter.adapt(response)),
    catchError(error => {
      // let errorMessage = 'An unexpected error occurred. Please try again later.';

      if (error.status === 500) {
        this.errorMessages = 'Internal Server Error. Something went wrong.';
      } else if (error.status === 404) {
        this.errorMessages = 'Resource not found. Please check your request.';
      }

      this.snackBar.open(this.errorMessages, 'Close', {
        duration: 5000,
      });

      this.loader.close();
      return of(null);
    }));
  }


  resetPassword(formData): Observable<IUserAPI> {
    return this.apiService.post('reset', formData).pipe(
		  catchError((error) => {
			// let errorMessage = 'An unexpected error occurred. Please try again later.';
			if (error.status === 500) {
			  this.errorMessages = 'Internal Server Error. Something went wrong.';
			} else if (error.status === 404) {
			  this.errorMessages = 'Resource not found. Please check your request.';
			} 
			this.snackBar.open(this.errorMessages, 'Close', {
			  duration: 5000,
			});
			this.loader.close();
			return of(null);
		  })
		);

  }


  chagePassword(model: User): Observable<IUserAPI> {
    const formData = new FormData();
    const token = decodedToken();
    formData.append('old_password', model.oldPassword);
    formData.append('new_password', model.newPassword);
    formData.append('email', get(token, 'aud'));
    return this.apiService.post('changepassword', formData).pipe(
		  catchError((error) => {
			// let errorMessage = 'An unexpected error occurred. Please try again later.';
			if (error.status === 500) {
			  this.errorMessages = 'Internal Server Error. Something went wrong.';
			} else if (error.status === 404) {
			  this.errorMessages = 'Resource not found. Please check your request.';
			} 
			this.snackBar.open(this.errorMessages, 'Close', {
			  duration: 5000,
			});
			this.loader.close();
			return of(null);
		  })
		);

  }

  getCurrentUser(): User {
    return JSON.parse(window.localStorage.getItem('sesUsr'));
  }


  get isLoggedIn() {
    return this.isAuthenticatedSubject.asObservable();
  }

  logout() {
    this.getPortalType();
    this.purgeAuth();
    this.goToLogin();
  }

  getSessionUser(): User {
    return JSON.parse(window.localStorage.getItem('sesUsr'));
  }


  goToLogin() {
    if (this.portalType == PORTALTYPE.GYM) {
      this.router.navigate(['/']);
    } else if (this.portalType == PORTALTYPE.DIETITIAN) {
      this.router.navigate(['/dietitian']);
    } else if (this.portalType == PORTALTYPE.PHYSIOTHERAPIST) {
      this.router.navigate(['/physiotherapist']);
    } else {
      this.router.navigate(['/']); //default
    }
  }



  getPortalType() {
    const token = decodedToken();
    this.portalType = get(token, 'type') ?? '';
  }


  pushSosNotifies(sos) {
    this.arrayOfSosSub.next(sos)
  }

  siteSettings() {
    return this.apiService.getSiteInfo('sitesetting').pipe(map((response:any) => this._siteInfo.next(response.data)),
    catchError(error => {
      // let errorMessage = 'An unexpected error occurred. Please try again later.';

      if (error.status === 500) {
        this.errorMessages = 'Internal Server Error. Something went wrong.';
      } else if (error.status === 404) {
        this.errorMessages = 'Resource not found. Please check your request.';
      }

      this.snackBar.open(this.errorMessages, 'Close', {
        duration: 5000,
      });

      this.loader.close();
      return of(null);
    }));
  }

  // getSiteSettings(): BehaviorSubject<any> {
  //   return this.currentMessage;
  // }

}
